<template>
  <div class="noticeBox">
    <NavBar title="公告"></NavBar>
    <div class="contentBox flexAuto flexColumn">
      <ListView :get-list="getList" empty="暂无公告" pull-refresh>
        <template v-slot="{ data }">
          <ListBox
            :title="item.title"
            :intro="item.time"
            v-for="(item, index) of data"
            :key="index"
            @click.native="goDetail(item.id)"
          ></ListBox>
        </template>
      </ListView>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {};
  },
  created() {},
  // mounted () {},
  methods: {
    async getList(page) {
      let res = await this.$http('post', '/v1/noticeCenter/info', {
        page: page,
        size: 5,
      });
      res.data.list = res.data.list.map(item => {
        return {
          id: item.id,
          title: item.title,
          time: item.createTime,
        };
      });
      return res;
    },
    goDetail(id) {
      let url = `/v1/noticeCenter/getId?id=${id}`;
      this.$router.push(`/other/detail?title=公告详情&url=${url}`);
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
